// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from 'firebase/app'

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyB598hDrWkaIWISKrEUOZaxi7WdbddCgh0',
  authDomain: 'measuring-tool.firebaseapp.com',
  databaseURL: 'https://measuring-tool.firebaseio.com',
  projectId: 'measuring-tool',
  storageBucket: 'measuring-tool.appspot.com',
  messagingSenderId: '543601270326',
  appId: '1:543601270326:web:7d023c700c70c66f16c297',
  measurementId: 'G-HZ10E4ZFV7',
}

firebase.initializeApp(firebaseConfig)

export const analytics = firebase.analytics()
