import React, { useState, useEffect } from 'react'
import Measurement from './Measurement'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/core/styles'
import { isMobile } from 'react-device-detect'
import { analytics } from '../firebase'
import { convertFromMeters } from '../utils'

export default function MeasurementViewer({
  measurements,
  handleDelete,
  handleChangeName,
}) {
  const theme = useTheme()

  const [unit, setUnit] = useState('m')

  const [anchorEl, setAnchorEl] = React.useState(null)

  const saveMeasurements = () => {
    const measurementData = measurements.reduce((acc, m) => {
      acc[m.name] = convertFromMeters(m.distance, unit)
      return acc
    }, {})
    measurementData['unit'] = unit

    const fileData = JSON.stringify(measurementData, null, 2)
    const blob = new Blob([fileData], { type: 'text/plain' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.download = `measurements-${Date.now()}.json`
    link.href = url
    link.click()
    analytics.logEvent('measurement_download')
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleUnitSelect = (unit) => {
    setUnit(unit)
    handleClose()
  }

  return (
    <div
      style={{
        maxHeight: isMobile && 150,
        overflowY: isMobile && 'scroll',
        overflowX: isMobile && 'hidden',
      }}
    >
      {measurements.length > 0 ? (
        <>
          {!isMobile && (
            <Typography gutterBottom style={{ marginTop: theme.spacing(3) }}>
              Measurements
            </Typography>
          )}
          <Grid
            container
            spacing={2}
            style={{ marginTop: theme.spacing(isMobile ? 0 : 3) }}
          >
            <Grid item>
              <Button onClick={handleClick}>
                <KeyboardArrowDownIcon />
                {`Units (${unit})`}
              </Button>
              <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem value='mm' onClick={() => handleUnitSelect('mm')}>
                  mm
                </MenuItem>
                <MenuItem onClick={() => handleUnitSelect('cm')}>cm</MenuItem>
                <MenuItem onClick={() => handleUnitSelect('m')}>m</MenuItem>
                <MenuItem onClick={() => handleUnitSelect('in')}>in</MenuItem>
                <MenuItem onClick={() => handleUnitSelect('ft')}>ft</MenuItem>
              </Menu>
            </Grid>
            <Grid item>
              <Button
                size='small'
                variant='outlined'
                color='primary'
                onClick={saveMeasurements}
              >
                Export JSON
              </Button>{' '}
            </Grid>
          </Grid>

          {[...measurements].reverse().map((measurement, i) => (
            <Measurement
              color={measurement.color}
              distance={measurement.distance}
              unit={unit}
              key={measurement.name}
              name={measurement.name}
              onChangeName={handleChangeName}
              onDoubleClick={() => handleDelete(measurement)}
            />
          ))}
        </>
      ) : (
        <Typography gutterBottom style={{ marginTop: theme.spacing(3) }}>
          Calculate a perimeter to create a measurement!
        </Typography>
      )}
    </div>
  )
}
