import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import warna from 'warna'
import { withStyles, useTheme } from '@material-ui/core/styles'
import { isMobile } from 'react-device-detect'
// material
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
// icons
import CloseIcon from '@material-ui/icons/Close'
import PublishIcon from '@material-ui/icons/Publish'
import WarningIcon from '@material-ui/icons/Warning'
// utils
import { getFileExtension, getScaleFromFileName } from '../utils'
import { SUPPORTED_FILE_TYPES } from '../constants'
import { useAppState } from '../hooks/useAppState'

import About from './About'
import { ScandyAppBadges } from '../pages/Home'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

export default function Upload() {
  const theme = useTheme()
  const {
    isLanding,
    isUploadDialogOpen,
    setIsUploadDialogOpen,
    handleFileUpload,
    setIsDrawerOpen,
    setIsLanding,
  } = useAppState()

  const [file, setFile] = useState(null)
  const [error, setError] = useState(null)
  const [unit, setUnit] = useState(null)

  const handleFileChange = (uploadedFile) => {
    const file = uploadedFile[0]
    if (file) {
      const file_type = getFileExtension(file.name)
      if (SUPPORTED_FILE_TYPES.includes(file_type)) {
        setError(null)
        const scale = getScaleFromFileName(file.name)
        setUnit(scale)
      } else {
        setError('Error: file must be .stl, .obj or .ply')
      }
    }
    setFile(file)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    handleFileUpload({ upload_file: file, scale: unit })
    setIsLanding(false)
    setFile(null)
    setError(null)
    setUnit('m')
    setTimeout(() => {
      setIsDrawerOpen(true)
    }, 500)
  }

  const handleChange = (event) => {
    setUnit(event.target.value)
  }

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(3),
    },
  }))(MuiDialogContent)

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(0),
    },
  }))(MuiDialogActions)

  return (
    <Dialog
      open={isUploadDialogOpen}
      fullScreen={isMobile ? true : false}
      onClose={() => !isLanding && setIsUploadDialogOpen(false)}
      aria-labelledby='Welcome to Mesh Measuring Tool by Scandy!'
      style={{
        marginBottom: isMobile ? 0 : theme.spacing(20),
        marginTop: isMobile && theme.spacing(12),
      }}
    >
      <DialogTitle
        isLanding={isLanding}
        id='Welcome to Mesh Measuring Tool by Scandy!'
        onClose={() => setIsUploadDialogOpen(false)}
      >
        {isLanding ? 'Welcome to Mesh Measuring Tool by Scandy!' : 'Upload'}
        <About buttonStyle={{ marginBottom: 3, marginLeft: 8 }} />
      </DialogTitle>

      <DialogContent dividers>
        <Typography gutterBottom>
          Measure the perimeter at any point along a 3D object with the simple
          click of a button. Upload a file to get started!
        </Typography>
        <Dropzone
          accept={SUPPORTED_FILE_TYPES.map((f) => `.${f.toLowerCase()}`).join(
            ', '
          )}
          onDrop={(files) => handleFileChange(files)}
        >
          {({ getRootProps, getInputProps }) => (
            <section
              style={{
                border: `1px dashed ${theme.palette.common.white}`,
                borderRadius: 4,
                display: 'flex',
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(2),
                cursor: 'pointer',
              }}
            >
              <div
                {...getRootProps()}
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  padding: theme.spacing(4),
                }}
              >
                <input {...getInputProps()} />
                {file && !error ? (
                  <>
                    <Typography
                      variant='h6'
                      style={{
                        color: theme.palette.primary.main,
                        textAlign: 'center',
                      }}
                      gutterBottom
                    >
                      {file.name}
                    </Typography>
                    <Typography style={{ textAlign: 'center' }} gutterBottom>
                      Drag or select another 3D file here to replace
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography
                      style={{
                        textAlign: 'center',
                        // color: theme.palette.primary.light,
                      }}
                      gutterBottom
                    >
                      Drag 'n' drop your 3D file here, or click to select
                    </Typography>
                    <PublishIcon
                      fontSize='large'
                      style={{
                        color: theme.palette.primary.main,
                        marginTop: theme.spacing(1),
                      }}
                    />
                  </>
                )}
              </div>
            </section>
          )}
        </Dropzone>
        {error && (
          <Typography
            style={{
              textAlign: 'center',
              color: theme.palette.secondary.dark,
              padding: theme.spacing(1),
              borderRadius: 4,
              backgroundColor: warna.lighten(theme.palette.secondary.light, 0.7)
                .hex,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            gutterBottom
          >
            <WarningIcon style={{ marginRight: theme.spacing(1) }} />
            {error}
          </Typography>
        )}
        {isLanding && !file && (
          <div id='badges-landing'>
            <ScandyAppBadges />
          </div>
        )}
        {!error && file && (
          <>
            <FormControl
              component='fieldset'
              style={{
                width: '100%',
                flexDirection: 'row',
                display: 'flex',
              }}
            >
              <RadioGroup
                aria-label='units(metric)'
                name='units(metric)'
                value={unit}
                onChange={handleChange}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  gutterBottom
                  variant='h6'
                  style={{ marginTop: 5, marginRight: theme.spacing(2) }}
                >
                  Scale:
                </Typography>
                {['mm', 'cm', 'm'].map((unit, i) => (
                  <FormControlLabel
                    key={i}
                    value={unit}
                    control={<Radio color='primary' />}
                    label={unit}
                    labelPlacement='start'
                    style={{
                      flex: 1,
                      margin: 0,
                      padding: 0,
                      justifyContent: 'center',
                      flexDirection: isMobile ? 'column' : 'row',
                    }}
                  />
                ))}
                {['in', 'ft'].map((unit, i) => (
                  <FormControlLabel
                    key={i * 2}
                    value={unit}
                    control={<Radio color='primary' />}
                    label={unit}
                    labelPlacement='start'
                    style={{
                      flex: 1,
                      margin: 0,
                      padding: 0,
                      justifyContent: 'center',
                      flexDirection: isMobile ? 'column' : 'row',
                    }}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='primary'
          style={{
            fontSize: 20,
            width: '100%',
            borderRadius: 0,
          }}
          disabled={error || !file}
          onClick={(e) => {
            handleSubmit(e)
            setIsUploadDialogOpen(false)
          }}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, isLanding, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose && !isLanding ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})
