import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { isMobile } from 'react-device-detect'

export default function PlaneSlider({ render, maxY, maxDimension, plane }) {
  const [y, setY] = useState(0)
  const [x, setX] = useState(plane.scale.x)
  const [z, setZ] = useState(plane.scale.z)

  const handleInput = (e) => {
    plane.position.y = y
    plane.scale.set(x, 1, z)
    render()
  }

  return (
    <div>
      {!isMobile && (
        <Typography style={{ marginBottom: 16 }}>
          Adjust Measuring Plane
        </Typography>
      )}
      <Grid container spacing={1} justify='center' alignItems='center'>
        <Grid item>
          <Typography style={{ marginBottom: 5 }}>X</Typography>
        </Grid>
        <Grid item xs>
          <input
            type='range'
            id='x'
            min={0}
            max={maxDimension * 1.5}
            step='any'
            value={x}
            onChange={(e) => setX(e.nativeEvent.target.value)}
            onInput={handleInput}
            className='slider'
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} justify='center' alignItems='center'>
        <Grid item>
          <Typography style={{ marginBottom: 5 }}>Y</Typography>
        </Grid>
        <Grid item xs>
          <input
            type='range'
            id='y'
            min={(maxY / 2) * -1.5}
            max={(maxY / 2) * 1.5}
            step='any'
            value={y}
            onChange={(e) => setY(e.nativeEvent.target.value)}
            onInput={handleInput}
            className='slider'
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} justify='center' alignItems='center'>
        <Grid item>
          <Typography style={{ marginBottom: 5 }}>Z</Typography>
        </Grid>
        <Grid item xs>
          <input
            type='range'
            id='z'
            min={0}
            max={maxDimension * 1.5}
            step='any'
            value={z}
            onChange={(e) => setZ(e.nativeEvent.target.value)}
            onInput={handleInput}
            className='slider'
          />
        </Grid>
      </Grid>
    </div>
  )
}
