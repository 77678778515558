import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import HelpIcon from '@material-ui/icons/Help'
import Typography from '@material-ui/core/Typography'
import { isMobile } from 'react-device-detect'
import { ScandyAppBadges } from '../pages/Home'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogButton: {
    zIndex: 10000,
  },
})

const DialogButton = withStyles(styles)((props) => {
  const { children, classes, onClick, ...other } = props
  return (
    <IconButton
      {...other}
      aria-label='close'
      className={classes.dialogButton}
      size='small'
      onClick={onClick}
    >
      <HelpIcon fontSize='small' />
    </IconButton>
  )
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function About({ buttonStyle }) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {!open && <DialogButton onClick={handleClickOpen} style={buttonStyle} />}
      {open && (
        <Dialog
          onClose={handleClose}
          aria-labelledby='about-mesh-measuring-tool'
          open={open}
          fullScreen={isMobile ? true : false}
        >
          <DialogTitle id='customized-dialog-title' onClose={handleClose}>
            About the Mesh Measuring Tool
          </DialogTitle>

          <DialogContent dividers>
            <div style={{ marginBottom: 32 }}>
              <ScandyAppBadges />
            </div>
            <Typography variant='h6'>
              What is the purpose of this app?
            </Typography>
            <Typography
              gutterBottom
              style={{ fontWeight: 100, marginBottom: 24 }}
            >
              The Mesh Measuring Tool allows users to measure the perimeter at
              any point along a 3D object.
            </Typography>
            <Typography variant='h6'>How do I use this app?</Typography>
            <Typography
              gutterBottom
              style={{ fontWeight: 100, marginBottom: 24 }}
            >
              Choose a file, select the model's scale and click <b>Upload</b>.
              Once the mesh has loaded, you will see a grey plane - the{' '}
              <i>measuring plane</i>. Use this plane to select where on the
              model you would like to measure. Adjust the height, width, and
              depth of the plane using the sliders on the bottom of the screen.
              Adjust the rotation of the model by selecting <b>Rotate</b> under{' '}
              <b>Mesh Controls</b> (or press the <b>R</b> key) and dragging
              along the arrows wrapped around the mesh. Adjust the translation
              of the model by selecting <b>Translate</b> under{' '}
              <b>Mesh Controls</b> (or press the <b>T</b> key) and dragging the
              arrows & squares. Rotate the camera by dragging anywhere on the
              screen and zoom in by scrolling up & down or pinching in & out.
            </Typography>
            <Typography
              gutterBottom
              style={{ fontWeight: 100, marginBottom: 24 }}
            >
              Once the plane is intersecting your mesh where you'd like to
              measure the perimeter, click <b>Calculate Perimeter</b>. A line
              will wrap around the model to visualize the perimeter and the
              calculation will appear in the <b>Measurements</b> tab in the top
              right. Change the unit of measurement using the <b>Unit</b>{' '}
              dropdown and double-click a measurement to remove it. Change the
              name of the measurement by clicking the text field next to it and
              entering the desired name. Click <b>Export JSON</b> to save all
              your measurements as a simple JSON file.
            </Typography>
            <Typography variant='h6'>
              Help! I clicked <b>Calculate Perimeter</b> and my mesh was
              swallowed by a big colorful blob!
            </Typography>
            <Typography
              gutterBottom
              style={{ fontWeight: 100, marginBottom: 24 }}
            >
              You most likely imported your model with the wrong scale. Try
              changing the value of <b>Scale</b>, reupload the mesh and
              calculate perimeter again. Another hint that your model is in the
              wrong scale is if the measurements look extremely inaccurate.
            </Typography>
            <Typography variant='h6'>
              Which 3D file types are supported?
            </Typography>
            <Typography
              gutterBottom
              style={{ fontWeight: 100, marginBottom: 24 }}
            >
              At this time, STL, OBJ & PLY models can be imported.
            </Typography>
            <Typography variant='h6'>
              How do I get a 3D scan of an object?
            </Typography>
            <Typography
              gutterBottom
              style={{ fontWeight: 100, marginBottom: 24 }}
            >
              Check out{' '}
              <a
                href='https://apps.apple.com/us/app/scandy-pro/id1388028223'
                target='_blank'
                rel='noopener noreferrer'
              >
                Scandy Pro
              </a>{' '}
              or{' '}
              <a
                href='https://apps.apple.com/us/app/stl-maker/id1444230836'
                target='_blank'
                rel='noopener noreferrer'
              >
                STL Maker
              </a>
              .
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color='primary'>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
