import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { isMobile } from 'react-device-detect'
import { Drawer as MADrawer } from '@material-ui/core/'

import { useAppState } from '../hooks/useAppState'

const drawerWidth = isMobile ? null : 400

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    zIndex: 4,
  },
  drawerPaper: {
    width: drawerWidth,
    // backgroundColor: 'rgba(180, 180, 180, .3)',
    backgroundColor: 'rgb(62 62 62 / .91)',
  },
}))

export default function Drawer({ children }) {
  const classes = useStyles()
  const { isDrawerOpen } = useAppState()
  return (
    <MADrawer
      className={classes.drawer}
      variant='persistent'
      anchor={isMobile ? 'bottom' : 'left'}
      open={isDrawerOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div
        style={{ position: 'relative', overflow: isMobile ? 'hidden' : 'auto' }}
      >
        {children}
      </div>
    </MADrawer>
  )
}
