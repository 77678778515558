import React, { useState, useContext, createContext } from 'react'
import { analytics } from '../firebase'

export const AppStateContext = createContext(false)

export const useAppState = () => useContext(AppStateContext)

export const AppStateProvider = ({ children }) => {
  const appState = useAppStateProvider()
  return (
    <AppStateContext.Provider value={appState}>
      {children}
    </AppStateContext.Provider>
  )
}

const useAppStateProvider = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(true)

  const [file, setFile] = useState(null)
  const [scale, setScale] = useState(null)
  const [meshRendered, setMeshRendered] = useState(false)
  const [isLanding, setIsLanding] = useState(true)

  const handleFileUpload = ({ upload_file, scale }) => {
    setMeshRendered(false)
    setFile(upload_file)
    setScale(scale)
    analytics.logEvent('file_upload')
  }

  const handleFileUploadCancel = () => {
    setFile(null)
  }

  return {
    isDrawerOpen,
    isUploadDialogOpen,
    file,
    scale,
    meshRendered,
    isLanding,
    setIsDrawerOpen,
    setIsUploadDialogOpen,
    setFile,
    setScale,
    setMeshRendered,
    handleFileUpload,
    handleFileUploadCancel,
    setIsLanding,
  }
}

export const withAppStateHOC = (Component: any) => {
  return (props: any) => {
    const appState = useAppState()

    return <Component appState={appState} {...props} />
  }
}
