// Shout out to https://github.com/mrdoob/three.js/blob/master/examples/misc_controls_transform.html

import React, { Component } from 'react'
import * as THREE from 'three'
// import Loader from "react-loader-spinner";
import { PLYLoader } from '../loaders/PLYLoader'
import { STLLoader } from '../loaders/STLLoader'
import { GLTFLoader } from '../loaders/GLTFLoader'
import { OBJLoader } from '../loaders/OBJLoader'
import { OrbitControls } from '../three/OrbitControls'
import { TransformControls } from '../three/TransformControls'
import { MeshLine, MeshLineMaterial } from 'three.meshline'
import { isMobile } from 'react-device-detect'
import ThreeSixtyIcon from '@material-ui/icons/ThreeSixty'
import ListIcon from '@material-ui/icons/List'
import TuneIcon from '@material-ui/icons/Tune'
import Loader from 'react-loader-spinner'

import Drawer from '../components/Drawer'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import Button from '@material-ui/core/Button'

import { getFileExtension, convertToMeters, unitString } from '../utils'
import PlaneSlider from './PlaneSlider'
import MeasurementViewer from './MeasurementViewer'
import SceneOptions from './SceneOptions'
import About from './About'
import { withAppStateHOC } from '../hooks/useAppState'

var ScandyMint = require('../loaders/scandy-logo.glb')
var _ = require('lodash')
var convexHull = require('monotone-convex-hull-2d')

class MeshView extends Component {
  constructor(props) {
    super(props)
    this.initState = {
      meshHeight: 0,
      meshWidth: 0,
      meshDepth: 0,
      measurements: [],
      meshRendered: false,
      loading: false,
      menuValue: 0,
      transformType: null,
    }
    this.state = this.initState
  }
  scene = null
  renderer = null
  camera = null
  meshControls = null

  /**
   * @param  {File} {file
   * @param  {string} file_path}
   */
  loadMesh = ({ file, file_path }) => {
    var loader
    const scale = this.props.scale
    if (scale !== 'm') {
      var scaleToMeters = window.confirm(
        `It looks like this scan is scaled to ${unitString(
          scale
        )}. In order for measurements to be accurate, your scan will be rescaled to meters. Click 'OK' to rescale your mesh and continue.`
      )
      if (!scaleToMeters) {
        this.props.handleFileUploadCancel()
        return
      }
    }
    var file_path = file ? URL.createObjectURL(file) : file_path
    const file_type = file
      ? getFileExtension(file.name)
      : getFileExtension(file_path)

    switch (file_type) {
      case 'PLY':
        loader = new PLYLoader()
        break
      case 'OBJ':
        loader = new OBJLoader()
        break
      case 'STL':
        loader = new STLLoader()
        break
      default:
        return
    }

    loader.load(file_path, (res) => {
      var geometry
      switch (file_type) {
        case 'OBJ':
          // Obj returns a group containing material
          geometry = res.children[0].geometry
          this.mesh = res.children[0]
          break
        case 'STL':
        case 'PLY':
          geometry = res
          geometry = geometry.toNonIndexed()
          var material = new THREE.MeshStandardMaterial({
            color: 0xf5f5f5,
            side: THREE.DoubleSide,
          })
          this.mesh = new THREE.Mesh(geometry, material)
          break
        default:
          return
      }

      geometry.computeVertexNormals()

      geometry.center()

      this.mesh.castShadow = true
      this.mesh.receiveShadow = true
      if (scaleToMeters) {
        this.mesh.scale.set(
          convertToMeters(1, scale),
          convertToMeters(1, scale),
          convertToMeters(1, scale)
        )
      }
      this.renderScene()
    })
  }

  addBoundingBox = () => {
    //Calculate box helper dimensions
    var box = new THREE.Box3().setFromObject(this.mesh)
    this.setState({
      meshDepth: box.max.z - box.min.z,
      meshWidth: box.max.x - box.min.x,
      meshHeight: box.max.y - box.min.y,
    })

    //Add bounding box visualizer
    // var box_helper = new THREE.Box3Helper(box)
    // this.scene.add(box_helper)
    return { box }
  }

  addControlsToScene = ({ box, box_helper }) => {
    //Add orbit & controls
    var orbit = new OrbitControls(this.camera, this.renderer.domElement)
    orbit.update()
    orbit.addEventListener('change', this.renderRenderer)

    this.meshControls = new TransformControls(
      this.camera,
      this.renderer.domElement
    )

    this.meshControls.addEventListener('change', () => {
      //Calculate dimensions
      if (this.meshControls.getMode() === 'rotate') {
        //Update bounding box measurements
        box.setFromObject(this.mesh)
        const meshDepth = box.max.z - box.min.z
        const meshWidth = box.max.x - box.min.x
        const meshHeight = box.max.y - box.min.y
        this.setState((currentState) => {
          if (
            currentState.meshDepth !== meshDepth ||
            currentState.meshWidth !== meshWidth ||
            currentState.meshHeight !== meshHeight
          ) {
            return { meshDepth, meshHeight, meshWidth }
          }
        })
      }
      this.renderRenderer()
    })

    this.meshControls.addEventListener('dragging-changed', function (event) {
      orbit.enabled = !event.value
    })

    this.meshControls.attach(this.mesh)
    this.meshControls.setMode('rotate')
    this.meshControls.name = `transform_helper`
    this.scene.add(this.meshControls)
    this.setState({ transformType: this.meshControls.getMode() })
  }

  renderRenderer = () => {
    this.renderer.render(this.scene, this.camera)
  }

  createRenderer = () => {
    this.renderer = new THREE.WebGLRenderer({ antialias: true })
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(window.innerWidth, window.innerHeight)
  }

  createCamera = () => {
    const aspect = window.innerWidth / window.innerHeight
    this.camera = new THREE.PerspectiveCamera(50, aspect, 0.01, 30000)
    this.camera.position.set(0, 0.2, 2)
  }

  createScene = () => {
    this.scene = new THREE.Scene()
  }

  addHelpersToScene = () => {
    // Axes helper
    var axis_helper = new THREE.AxesHelper(2)
    axis_helper.name = 'axis_helper'
    this.scene.add(axis_helper)
  }

  addLighting = () => {
    const light_vertices = [
      [5, 0, 5],
      [-5, 0, -5],
      [-5, 0, 5],
      [5, 0, -5],
    ]
    light_vertices.forEach((v) => {
      var light = new THREE.DirectionalLight(0xffffff, 0.6)
      light.position.set(...v)
      this.scene.add(light)
    })
  }

  addMeasuringPlane = () => {
    const { meshHeight, meshWidth, meshDepth } = this.state
    const maxPlaneDimensions = Math.max(meshHeight, meshWidth, meshDepth)
    var plane_geometry = new THREE.PlaneGeometry(1, 1)

    plane_geometry.rotateX(-Math.PI / 2)
    var plane = new THREE.Mesh(
      plane_geometry,
      new THREE.MeshBasicMaterial({
        color: 'gray',
        transparent: true,
        opacity: 0.55,
        side: THREE.DoubleSide,
      })
    )
    plane.scale.set(maxPlaneDimensions, 1, maxPlaneDimensions)

    plane.name = 'measuring-plane'
    this.scene.add(plane)
  }

  addMeshToScene = () => {
    this.scene.add(this.mesh)
    const boundingBox = this.addBoundingBox()
    this.addControlsToScene(boundingBox)
  }

  animate = (mesh) => {
    if (!this.mesh) {
      requestAnimationFrame(() => this.animate(mesh))
      mesh.rotation.x -= 0.02
      mesh.rotation.x -= 0.02

      this.renderRenderer()
    }
  }

  addScandyMintMesh = () => {
    var loader = new GLTFLoader()

    loader.load(ScandyMint, (object) => {
      const mesh = object.scene.children[0].children[0]
      const geometry = mesh.geometry

      geometry.rotateZ(-Math.PI / 4)
      mesh.scale.set(0.055, 0.055, 0.055)
      mesh.position.y -= 0.35

      var light = new THREE.HemisphereLight(0xffffbb, 0x080820, 8)
      this.scene.add(light)
      this.scene.add(mesh)

      this.animate(mesh)
    })
  }

  renderScene = () => {
    this.createRenderer()
    this.createCamera()
    this.createScene()
    this.addLighting()

    //Add mesh
    if (this.mesh) {
      this.addMeshToScene()
      this.addHelpersToScene()
      this.addMeasuringPlane()
      this.setState({ meshRendered: true })
      this.props.handleMeshRendered()
    } else {
      this.addScandyMintMesh()
    }

    //TODO: maybe put in clipping
    this.renderer.localClippingEnabled = true

    this.renderRenderer()
    //Mount our renderer to dom
    if (this.mount.childNodes.length > 0) {
      //If renderer is mounted, replace it
      this.mount.replaceChild(
        this.renderer.domElement,
        this.mount.childNodes[0]
      )
    } else {
      this.mount.appendChild(this.renderer.domElement)
    }
  }

  // Not being used but could be if we want it :shrug:
  addWireframe = () => {
    var wireframe = new THREE.WireframeGeometry(this.mesh.geometry)

    var wireframe_line = new THREE.LineSegments(wireframe)
    wireframe_line.material.depthTest = false
    wireframe_line.material.opacity = 0.25
    wireframe_line.material.transparent = true

    this.scene.add(wireframe_line)
  }

  findIntersectionPoints = (plane) => {
    // Create a "Math Plane" which is basically cloned from the visualized plane
    var planePointA = new THREE.Vector3(),
      planePointB = new THREE.Vector3(),
      planePointC = new THREE.Vector3()

    var mathPlane = new THREE.Plane()
    plane.localToWorld(
      planePointA.copy(plane.geometry.vertices[plane.geometry.faces[0].a])
    )
    plane.localToWorld(
      planePointB.copy(plane.geometry.vertices[plane.geometry.faces[0].b])
    )
    plane.localToWorld(
      planePointC.copy(plane.geometry.vertices[plane.geometry.faces[0].c])
    )
    mathPlane.setFromCoplanarPoints(planePointA, planePointB, planePointC)

    // This is where we will store all points that intersect our plane
    var pointsOfIntersection = new THREE.Geometry()

    // Variables to be reused on each iteration to store the points/vertices/lines we are currently checking
    var a = new THREE.Vector3(),
      b = new THREE.Vector3(),
      c = new THREE.Vector3()
    var lineAB = new THREE.Line3(),
      lineBC = new THREE.Line3(),
      lineCA = new THREE.Line3()

    //Function that checks if our point intersects the plane; if so, add to pointsOfIntersection
    var pointOfIntersection
    var measuring_plane = this.scene.getObjectByName('measuring-plane')
    var pos_x_bound = measuring_plane.scale.x * 0.5
    var neg_x_bound = measuring_plane.scale.x * -0.5
    var pos_z_bound = measuring_plane.scale.z * 0.5
    var neg_z_bound = measuring_plane.scale.z * -0.5

    function setPointOfIntersection(line, plane, mesh) {
      var infinitePlaneIntersects = plane.intersectsLine(line)

      if (infinitePlaneIntersects) {
        pointOfIntersection = plane.intersectLine(line, new THREE.Vector3())
        // Check if intersects visualized plane
        const finitePlaneIntersects =
          neg_x_bound <= pointOfIntersection.x &&
          pointOfIntersection.x <= pos_x_bound &&
          neg_z_bound <= pointOfIntersection.z &&
          pointOfIntersection.z <= pos_z_bound
        if (finitePlaneIntersects) {
          pointsOfIntersection.vertices.push(pointOfIntersection.clone())
        }
      }
    }

    const geometry = this.mesh.geometry
    const num_faces = geometry.attributes.position.count
    const vertices = geometry.attributes.position.array

    // Loop through our vertices, and find where they intersect with our plane
    for (let i = 0; i < num_faces; i++) {
      var vertex_a = new THREE.Vector3(
        vertices[i * 9],
        vertices[i * 9 + 1],
        vertices[i * 9 + 2]
      )
      var vertex_b = new THREE.Vector3(
        vertices[i * 9 + 3],
        vertices[i * 9 + 4],
        vertices[i * 9 + 5]
      )
      var vertex_c = new THREE.Vector3(
        vertices[i * 9 + 6],
        vertices[i * 9 + 7],
        vertices[i * 9 + 8]
      )

      this.mesh.localToWorld(a.copy(vertex_a))
      this.mesh.localToWorld(b.copy(vertex_b))
      this.mesh.localToWorld(c.copy(vertex_c))

      lineAB = new THREE.Line3(a, b)
      lineBC = new THREE.Line3(b, c)
      lineCA = new THREE.Line3(c, a)

      setPointOfIntersection(lineAB, mathPlane, this.mesh)
      setPointOfIntersection(lineBC, mathPlane, this.mesh)
      setPointOfIntersection(lineCA, mathPlane, this.mesh)
    }
    return pointsOfIntersection
  }

  handleCalculatePerimeter = () => {
    this.setState({ loading: true }, () => {
      // Give time for the UI to update
      setTimeout(this.calculatePerimeter, 1000)
    })
  }

  calculatePerimeter = (e) => {
    console.log('calculating....')
    var plane = this.scene.getObjectByName('measuring-plane')
    const pointsOfIntersection = this.findIntersectionPoints(plane)

    // Compute convex hull

    // Convert pointsOfIntersection to array of point pair arrays because thats what convexHull wants
    // eg: [[x1, z1], [x2, z2], ...] (using Z because our plane cuts through the Y-axis)
    var intersectionCoordsIn2d = []
    for (let i = 0; i < pointsOfIntersection.vertices.length; ++i) {
      intersectionCoordsIn2d.push([
        pointsOfIntersection.vertices[i].x,
        pointsOfIntersection.vertices[i].z,
      ])
    }

    var cells = convexHull(intersectionCoordsIn2d)

    cells = cells.map((cell, i) => {
      if (i === cells.length - 1) {
        return [cell, cells[0]]
      }
      return [cell, cells[i + 1]]
    })

    var distance = 0
    var pointsToDraw = []
    cells.forEach((cell, i) => {
      // Each cell is an index of where our coordinates are in the coords passed to convexHull
      //eg: intersectionCoordsIn2d[index] = [x, y]
      // console.log(plane.position.y === this.mesh.localToWorld(plane.position).y)

      var pointA = new THREE.Vector3(
        intersectionCoordsIn2d[cell[0]][0],
        plane.position.y,
        intersectionCoordsIn2d[cell[0]][1]
      )
      var pointB = new THREE.Vector3(
        intersectionCoordsIn2d[cell[1]][0],
        plane.position.y,
        intersectionCoordsIn2d[cell[1]][1]
      )

      this.mesh.worldToLocal(pointA)
      this.mesh.worldToLocal(pointB)

      pointsToDraw.push(pointA, pointB)

      distance += pointA.distanceTo(pointB)
    })
    distance *= this.mesh.scale.x

    var randomColor = `rgb(${Math.floor(
      Math.random() * Math.floor(256)
    )}, ${Math.floor(Math.random() * Math.floor(256))}, ${Math.floor(
      Math.random() * Math.floor(256)
    )})`

    var lineGeometry = new THREE.Geometry().setFromPoints(pointsToDraw)
    var line = new MeshLine()
    line.setGeometry(lineGeometry)

    const meshGeometry = this.mesh.geometry
    const meshScale = this.mesh.scale
    const meshVolume =
      meshGeometry.boundingBox.max.x *
      2 *
      meshScale.x *
      meshGeometry.boundingBox.max.y *
      2 *
      meshScale.y *
      meshGeometry.boundingBox.max.z *
      2 *
      meshScale.z

    const lineMaterial = new MeshLineMaterial({
      color: new THREE.Color(randomColor),
      lineWidth: 1.3e-5 * meshVolume + 10e-4, // Magical equation to get a decent line width
      side: THREE.DoubleSide,
    })

    const name = Date.now()
    const lineObject = new THREE.Mesh(line.geometry, lineMaterial)
    lineObject.name = name
    this.mesh.add(lineObject)

    // var points = new THREE.Points(
    //   pointsOfIntersection,
    //   new THREE.PointsMaterial({ size: 0.02 })
    // )
    // this.scene.add(points)
    this.setState({
      measurements: [
        ...this.state.measurements,
        { color: randomColor, distance, name },
      ],
      loading: false,
    })
    this.renderRenderer()
    isMobile && this.setState({ menuValue: 2 })
  }

  deleteMeasurement = ({ name }) => {
    var measurement = this.mesh.getObjectByName(name)
    while (measurement) {
      this.mesh.remove(measurement)
      measurement = this.mesh.getObjectByName(name)
    }

    const measurements = _.remove(
      this.state.measurements,
      (m) => m.name !== name
    )
    this.setState({ measurements })
    this.renderRenderer()
  }

  changeMeasurementName = ({ current_name, new_name }) => {
    var measurement = this.mesh.getObjectByName(current_name)
    measurement.name = new_name
    //There is probably a fancy lodash way to do this
    var measurements = this.state.measurements.map((m) => {
      if (m.name === current_name) {
        m.name = new_name
      }
      return m
    })
    this.setState({ measurements })
    this.renderRenderer()
  }

  onWindowResize = () => {
    this.camera.aspect = window.innerWidth / window.innerHeight
    this.camera.updateProjectionMatrix()

    this.renderer.setSize(window.innerWidth, window.innerHeight)
    this.renderRenderer()
  }

  onKeyDown = (e) => {
    switch (e.keyCode) {
      case 84: //T
        this.meshControls.setMode('translate')
        this.setState({ transformType: 'translate' })
        break
      case 82: //R
        this.meshControls.setMode('rotate')
        this.setState({ transformType: 'rotate' })
        break
      default:
        return
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize, false)
    window.addEventListener('orientationchange', this.onWindowResize, false)
    window.addEventListener('keydown', this.onKeyDown, false)
    this.renderScene()
  }

  componentDidUpdate(prevProps) {
    const { file, scale } = this.props
    const fileUpdated = file !== prevProps.file || scale !== prevProps.scale
    if (fileUpdated) {
      this.setState(this.initState)
      this.loadMesh({ file })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize)
    window.removeEventListener('orientationchange', this.onWindowResize)
    window.removeEventListener('keydown', this.onKeyDown)
  }

  render() {
    const {
      meshHeight,
      measurements,
      meshRendered,
      meshDepth,
      meshWidth,
    } = this.state

    return (
      <>
        {isMobile && !this.props.appState.isUploadDialogOpen && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 32,
              position: 'absolute',
              top: 50,
              left: 28,
              zIndex: 5,
            }}
          >
            <Button
              variant='contained'
              color='primary'
              size='small'
              style={{ marginRight: 16, marginTop: -5 }}
              disabled={this.state.loading}
              onClick={() => {
                this.handleCalculatePerimeter()
                this.props.appState.setIsDrawerOpen(true)
              }}
            >
              Calculate Perimeter
            </Button>
            <Loader
              type='Circles'
              color='#69ca5d'
              height={30}
              width={30}
              visible={this.state.loading}
            />
          </div>
        )}
        <Drawer>
          {meshRendered && (
            <>
              <div style={{ padding: 20, paddingTop: 80 }}>
                {this.state.menuValue === 0 ? (
                  <>
                    <PlaneSlider
                      handleCalculatePerimeter={this.handleCalculatePerimeter}
                      maxY={meshHeight}
                      maxDimension={Math.max(meshHeight, meshDepth, meshWidth)}
                      plane={this.scene.getObjectByName('measuring-plane')}
                      render={this.renderRenderer}
                    />
                    {!isMobile && (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginTop: 32,
                          }}
                        >
                          <Button
                            variant='contained'
                            color='primary'
                            size='large'
                            disabled={this.state.loading}
                            style={{ marginRight: 16, marginTop: -5 }}
                            onClick={this.handleCalculatePerimeter}
                          >
                            Calculate Perimeter
                          </Button>
                          <Loader
                            type='Circles'
                            color='#69ca5d'
                            height={30}
                            width={30}
                            visible={this.state.loading}
                          />
                        </div>
                        <MeasurementViewer
                          measurements={measurements}
                          handleDelete={this.deleteMeasurement}
                          handleChangeName={this.changeMeasurementName}
                        />
                      </>
                    )}
                  </>
                ) : this.state.menuValue === 1 ? (
                  <SceneOptions
                    scene={this.scene}
                    meshControls={this.meshControls}
                    render={this.renderRenderer}
                    transformType={this.state.transformType}
                    setTransformType={(transformType) => {
                      this.setState({ transformType })
                    }}
                  />
                ) : (
                  <MeasurementViewer
                    loading={this.state.loading}
                    measurements={measurements}
                    handleDelete={this.deleteMeasurement}
                    handleChangeName={this.changeMeasurementName}
                  />
                )}
              </div>
              <BottomNavigation
                value={this.state.menuValue}
                onChange={(event, newValue) => {
                  this.setState({ menuValue: newValue })
                }}
                showLabels
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  left: 0,
                }}
              >
                <BottomNavigationAction
                  label={isMobile ? 'Adjust Plane' : 'Measure'}
                  icon={<TuneIcon />}
                />
                <BottomNavigationAction
                  label='Transform Model'
                  icon={<ThreeSixtyIcon />}
                />
                {isMobile && (
                  <BottomNavigationAction
                    label='Measurements'
                    icon={<ListIcon />}
                  />
                )}
              </BottomNavigation>
            </>
          )}
        </Drawer>
        <div
          style={{ overflow: 'hidden' }}
          // style={{ height: '100%', width: '100%' }}
          // style={{ position: 'absolute', left: 0, right: 0, bottom: 0, top: 0 }}
        >
          <div ref={(ref) => (this.mount = ref)} />
          {!this.props.appState.isUploadDialogOpen && (
            <About
              buttonStyle={{ position: 'absolute', bottom: 5, right: 5 }}
            />
          )}
        </div>
      </>
    )
  }
}

export default withAppStateHOC(MeshView)
