import React from 'react'
import './App.css'
import Home from './pages/Home'
import { AppStateProvider } from './hooks/useAppState'
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles'
import warna from 'warna'

// disable warnings
console.warn = function () {}

const primary = '#69CA5D'
const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: primary,
      light: warna.lighten(primary, 0.75).hex,
      dark: warna.darken(primary, 0.5).hex,
    },
  },
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppStateProvider>
        <Home />
      </AppStateProvider>
    </ThemeProvider>
  )
}

export default App
