import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import { analytics } from '../firebase'
import { isMobile } from 'react-device-detect'

import MenuControls from '../components/MenuControls'
import Upload from '../components/Upload'
import MeshView from '../components/MeshView'

import { useAppState } from '../hooks/useAppState'

var ScandyProLogo = require('../assets/scandypro_secondary.png')
var STLMakerLogo = require('../assets/stlmaker_primary.png')
var AppStoreBadge = require('../assets/app-store-badge-black.svg')

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100vw',
  },
}))

export default function Home() {
  const classes = useStyles()

  const {
    file,
    scale,
    setMeshRendered,
    handleFileUploadCancel,
    isLanding,
  } = useAppState()

  useEffect(() => {
    analytics.logEvent('page_view')
  }, [])

  return (
    <div className={classes.root}>
      <ScandyLogo />
      <CssBaseline />
      {!isLanding && <MenuControls />}
      <Upload />
      <MeshView
        file={file}
        scale={scale}
        handleFileUploadCancel={handleFileUploadCancel}
        handleMeshRendered={() => setMeshRendered(true)}
      />
    </div>
  )
}

const ScandyLogo = () => (
  <div id='logo-container'>
    <img alt='Scandy' id='logo' src={require('../logotype-1.png')}></img>
  </div>
)

export const ScandyAppBadges = () => {
  const iconSize = isMobile ? 30 : 50
  return (
    <>
      <Typography gutterBottom>Need a 3D scan?</Typography>
      <div>
        <a href='http://scandypro.com/lky3' target='_blank'>
          <img
            src={ScandyProLogo}
            alt='scandy-pro'
            title='Scandy Pro'
            width={iconSize}
            height={iconSize}
            style={{ borderRadius: 6, marginLeft: 0 }}
          ></img>
        </a>
        <a href='http://scandypro.com/zaty' target='_blank'>
          <img
            src={STLMakerLogo}
            alt='stl-maker'
            title='STL Maker'
            width={iconSize}
            height={iconSize}
            style={{ borderRadius: 6, marginLeft: 8 }}
          ></img>
        </a>
        <a href='http://scandypro.com/lky3' target='_blank'>
          <img
            src={AppStoreBadge}
            alt='app-store-badge'
            style={{ borderRadius: 6, marginLeft: 8, height: iconSize }}
          ></img>
        </a>
      </div>
    </>
  )
}
