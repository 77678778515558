export const getFileExtension = (file_name) => {
  return file_name.substring(file_name.lastIndexOf('.') + 1).toUpperCase()
}

export const convertFromMeters = (length, unit) => {
  switch (unit) {
    case 'cm':
      return length * 100
    case 'mm':
      return length * 1000
    case 'm':
      return length
    case 'in':
      return length * 39.3701
    case 'ft':
      return length * 3.28084
    default:
      console.error('Unknown unit')
      return length
  }
}

export const convertToMeters = (length, unit) => {
  switch (unit) {
    case 'cm':
      return length / 100
    case 'mm':
      return length / 1000
    case 'm':
      return length
    case 'in':
      return length / 39.3701
    case 'ft':
      return length / 3.28084
    default:
      console.error('Unknown unit')
      return length
  }
}

export const unitString = (unit) => {
  switch (unit) {
    case 'cm':
      return 'centimeters'
    case 'mm':
      return 'millimeters'
    case 'm':
      return 'meters'
    case 'in':
      return 'inches'
    case 'ft':
      return 'feet'
    default:
      console.error('Unknown unit')
      return unit
  }
}

// Checks if file is Scandy Pro naming convention
// If so, looks for end tag that indicates scale unit
export const getScaleFromFileName = (file_name) => {
  let scandyProFileRe = /^(scandy-pro).*((-m)|(-cm)|(-mm)|(-in))\.stl$/gi
  let isScandyProFile = file_name.search(scandyProFileRe) > -1

  if (isScandyProFile) {
    return file_name.substring(
      file_name.lastIndexOf('-') + 1,
      file_name.lastIndexOf('.stl')
    )
  }
  return 'm' //Assume Meters by default
}
