import React, { useState, useRef } from 'react'
import { convertFromMeters } from '../utils'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { isMobile } from 'react-device-detect'

import { useTheme } from '@material-ui/core/styles'

export default function Measurement({
  color,
  distance,
  onDoubleClick,
  onChangeName,
  unit,
  name,
}) {
  const [newName, setNewName] = useState(name)
  const inputRef = useRef()
  const theme = useTheme()

  return (
    <Grid
      container
      spacing={isMobile ? 1 : 2}
      alignContent='center'
      alignItems='center'
      style={{ marginTop: theme.spacing(2) }}
    >
      <Grid item>
        <div
          id='color'
          style={{ backgroundColor: color }}
          onDoubleClick={onDoubleClick}
        ></div>
      </Grid>
      <Grid item>
        <div onDoubleClick={onDoubleClick}>
          <Typography>
            {convertFromMeters(distance, unit).toFixed(2)}
          </Typography>
        </div>
      </Grid>
      <Grid item>
        <TextField
          value={newName}
          ref={inputRef}
          onChange={(e) => setNewName(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              inputRef.current.blur()
            }
          }}
          onBlur={() => {
            if (name !== newName) {
              onChangeName({ current_name: name, new_name: newName })
            }
          }}
          label='Name'
          variant='outlined'
        />
      </Grid>
      <Grid item>
        <IconButton aria-label='delete' onClick={onDoubleClick}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}
