import React, { useState, useEffect } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import { useTheme } from '@material-ui/core/styles'
import { isMobile } from 'react-device-detect'

export default function SceneOptions({
  render,
  scene,
  meshControls,
  transformType,
  setTransformType,
}) {
  const [axisHelper, setAxisHelper] = useState(true)
  const [transformHelper, setTransformHelper] = useState(true)

  const theme = useTheme()

  return (
    <div
      id='scene-options'
      style={{ display: 'flex', flexDirection: isMobile ? 'row' : 'column' }}
    >
      <div style={{ width: isMobile ? '50%' : '100%' }}>
        <Typography gutterBottom>Helpers</Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={axisHelper}
              onChange={() => {
                setAxisHelper(!axisHelper)
                scene.getObjectByName('axis_helper').visible = !axisHelper
                render()
              }}
              name='axis_helper'
              color='primary'
            />
          }
          label='Show Axes'
          style={{ color: 'white' }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={transformHelper}
              onChange={() => {
                setTransformHelper(!transformHelper)
                scene.getObjectByName(
                  'transform_helper'
                ).visible = !transformHelper
                render()
              }}
              name='transform_helper'
              color='primary'
            />
          }
          label='Show Rotation Controls'
          style={{ color: 'white' }}
        />
      </div>
      <div style={{ width: isMobile ? '50%' : '100%' }}>
        <Typography
          gutterBottom
          style={{ marginTop: theme.spacing(isMobile ? 0 : 2) }}
        >
          Transform Type
        </Typography>

        <FormControl component='fieldset'>
          <RadioGroup
            aria-label='transform type'
            name='transform_type'
            value={transformType}
            onChange={(e) => {
              setTransformType(e.target.value)
              meshControls.setMode(e.target.value)
              render()
            }}
          >
            <FormControlLabel
              value='translate'
              control={<Radio color='primary' />}
              label='Translate'
            />
            <FormControlLabel
              value='rotate'
              control={<Radio color='primary' />}
              label='Rotate'
            />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  )
}
