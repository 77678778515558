import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import { isMobile } from 'react-device-detect'

import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'
import PublishIcon from '@material-ui/icons/Publish'
import { Tooltip } from '@material-ui/core'

import { useAppState } from '../hooks/useAppState'

const useStyles = makeStyles((theme) => ({
  fabContainer: {
    position: 'absolute',
    top: isMobile ? 0 : -20,
    // top: 0,
    // bottom: !isMobile ? 0 : 'auto',
    right: 0,
    zIndex: 4,
    padding: theme.spacing(isMobile ? 3 : 5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

export default function MenuControls() {
  const theme = useTheme()
  const classes = useStyles()
  const {
    isDrawerOpen,
    isUploadDialogOpen,
    setIsDrawerOpen,
    setIsUploadDialogOpen,
  } = useAppState()

  return (
    <div
      className={classes.fabContainer}
      style={{ opacity: isUploadDialogOpen ? 0.2 : 1 }}
    >
      <Tooltip
        title={isDrawerOpen ? 'Close Menu' : 'Open Menu'}
        placement='left'
      >
        <Fab
          size='large'
          color='primary'
          aria-label='menu'
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
          style={{ marginBottom: theme.spacing(2) }}
        >
          {isDrawerOpen ? <CloseIcon /> : <MenuIcon />}
        </Fab>
      </Tooltip>
      <Tooltip title='New Upload' placement='left'>
        <Fab
          size='small'
          color='primary'
          aria-label='menu'
          onClick={() => setIsUploadDialogOpen(!isUploadDialogOpen)}
        >
          <PublishIcon />
        </Fab>
      </Tooltip>
    </div>
  )
}
